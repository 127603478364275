#form{
    margin-bottom: 165px;
}


#form .form-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #0D0D19;
    margin: 53px 0 39px 126px;
}

#form .form-contents {
    max-width: 990px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 12px;
    margin: 0 80px 0 126px;
    padding: 33px 194px 32px 162px;
}

#form .form-contents p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 270px;
    color: #0D0D19;
    margin: 16px 0 43px 0;
}


#form .form-contents .form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #0D0D19;
    margin: 0;
}

#form .form-contents form p {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

#form .form-contents form input {
    border: 1px solid #1C2230;
    border-radius: 12px;
    width: 100%;
    height: 87px;
    padding: 0 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #0D0D19;
    margin-top: 4px;
    margin-bottom: 23px;
}

#form .form-contents form input::placeholder {
    color: #0D0D19;
}

#form .form-contents form .next-btn {
    background: #205295;
    border: 1px solid #1C2230;
    border-radius: 23px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    outline: none;
    gap: 32px;
    color: #FFFFFF;
    padding: 25px 36px 25px 72px;
    margin-top: 54px;
}






@media (max-width:1200px) {


    #form .form-contents {
        margin: 53px 0 39px 40px;
        padding: 33px 80px 32px 80px;
    }

    #form .form-contents form input {
        height: 70px;
    }

}

@media (max-width:1024px) {
    #form .form-heading {
        margin: 53px 0 39px 40px;
    }

    #form .form-contents {
        margin: 53px 0 39px 40px;
        padding: 33px 100px 32px 100px;
    }

    #form .form-contents form input {
        font-size: 18px;
        line-height: 24px;
        height: 50px;

    }
    #form .form-contents form .next-btn {
        font-size: 24px;
        line-height: 28px;
        outline: none;
        gap: 12px;
        padding: 15px 24px 15px 56px;
    }

}

@media (max-width:768px) {
    #form .form-heading {
        margin: 53px 0 39px 0px;
    }

    #form .form-contents {
        margin: 53px 0 39px 0px;
        padding: 33px;
    }
    #form{
        margin-bottom: 100px;
    }
    
}

@media (max-width:425px) {


    #form .form-contents {
        margin: 53px 0 39px 0px;
        padding: 16px;
    }
}