@media (min-width: 1350px) {
  .container {
    max-width: 1266px !important;
  }
}

.cmn-btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  color: #FFFFFF;
  background: #144272;
  border-radius: 10px;
  outline: none;
  border: 0;
}

@media (max-width:991px) {
  .cmn-btn {
    font-size: 15px;
    line-height: 16px;
  }
}