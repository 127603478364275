#navbar {
    padding: 25px 0;
}

#navbar ul {
    gap: 33px;
    margin: 0;
}

#navbar li {
    list-style-type: none;
}

#navbar li a {
    color: #0D0D19;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
}

#navbar button {
    padding: 16.50px 22.50px;
    margin-left: 72px;
    transition: 0.3s all ease;
    border: 1px solid transparent;
}

#navbar button:hover {
    background-color: white;
    border: 1px solid #144272;
    color: #144272;
}


/* mobile navbar */
#mobile-navbar{
    padding: 9px 0;
}

#mobile-navbar button {
    background-color: transparent;
    border: none;
}

#mobile-menu ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: -18px !important;

}

#mobile-menu ul li {
    list-style-type: none;
}

#mobile-menu li a {
    color: #0D0D19;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

#mobile-menu button {
    padding: 16.50px 22.50px;
    transition: 0.3s all ease;
    border: 1px solid transparent;
    margin-top: 10px;
}

#mobile-menu button:hover {
    background-color: white;
    border: 1px solid #144272;
    color: #144272;
}

.offcanvas {
    max-width: 70% !important;
}

.offcanvas-header {

    border-bottom: 1px solid rgb(205, 201, 201);
}


@media (max-width : 1200px) {
    #navbar {
        padding: 15px 0;
    }

    #navbar button {
        margin-left: 52px;
    }

    #navbar li a {
        font-size: 22px;
    }



}

@media (max-width : 991px) {
    #navbar {
        padding: 15px 0;
    }

    #navbar ul {
        gap: 15px;
    }

    #navbar li a {
        font-size: 20px;
    }

    #navbar button {
        padding: 13.50px 22.50px;
        margin-left: 45px;
        transition: 0.3s all ease;
        border: 1px solid transparent;
        white-space: nowrap;
    }
}