#footer {
    background: #1C1E23;
    mix-blend-mode: normal;
    border: 1px solid #000000;
    padding-top: 61px;
    padding-bottom: 20px;


}

#footer .footer-container {
    max-width: 1440px;
    padding: 0 178px 0 74px;
}


#footer .footer-container .footer-contents p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    max-width: 320px;
    color: #FFFFFF;
}

#footer .footer-container .footer-link-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 31px;
   
}


#footer .footer-container .footer-link-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 18px;
    color: #FFFFFF;
}

#footer .footer-container ul {
    list-style: none;
    margin-left: -32px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 0;

}

#footer .footer-container a {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: none;

}



#footer .footer-container .address {
    margin-left: 77px;
}


#footer .social-icons {
    gap: 16.78px;
}

.footer-bottom-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    color: #FFFFFF;
    margin-top: 95px;
}

.address-contents-1{
    display: none;
}



@media (max-width:1210px) {
    #footer .footer-container .address {
        margin-left: 0px;
    }

    #footer .footer-container .footer-link-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 18px;
        color: #FFFFFF;
    }

    #footer .footer-container .footer-contents p {

        width: 100%;
        font-size: 18px;
        line-height: 20px;

    }


}

@media (max-width:1024px) {
    #footer .footer-container {
        padding: 0 32px 0 32px;
    }

}

@media (max-width:900px) {
    #footer .footer-container .footer-contents {
        flex-direction: column;
        gap: 60px;
    }

    #footer .footer-container .footer-link-container {
        gap: 50px;
    }

   .address-contents{
        display: none;
    }

    .address-contents-1{
        display: block;
    }
    #footer .footer-container .footer-link-container {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 21px;
       
    }

}

@media (max-width:768px) {
    #footer .footer-container {
        padding: 0 32px 0 32px;
    }

    .footer-bottom-text {
        font-size: 16px;
    }

    #footer .footer-container ul {

        gap: 10px;


    }

}

@media (max-width:568px) {
    #footer .footer-container .footer-link-container {
        grid-template-columns: repeat(2,1fr);
        gap: 40px 10px;
        
    }


}