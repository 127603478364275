#buttons .wrapper {
    padding: 57px 40px 92px 40px;
}


#buttons .box {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 12px;
    padding: 9px 39px 49px 39px;
}

#buttons p {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #0D0D19;
    margin-bottom: 25px;
}

#buttons .buttons {
    grid-template-columns: repeat(4,1fr);
    gap: 62px;
}

#buttons .buttons button {
    display: inline-block;
    width: 100%;
    padding: 30px 0;
}


@media (max-width : 991px) {
    #buttons .buttons {
        gap: 42px;
       
    }
    #buttons .buttons button {
        padding: 20px 0;
    }
}
@media (max-width : 768px) {
    #buttons .buttons {
        display: grid;
        grid-template-columns: repeat(2,1fr); 
        gap: 20px;  
    }
    #buttons .buttons button {
        padding: 10px 5px;
    }
}
@media (max-width : 525px) {
   
    #buttons .buttons button {
        padding: 10px 15px;
        width: auto;
    }
    #buttons .wrapper {
        padding: 57px 10px 92px 10px;
    }
   
}