#hero-area {
    background-image: url("/public/images/banner.jpg");
    padding-top: 120px;
    padding-bottom: 126px;
    background-repeat: no-repeat;
    background-size: cover;
}

#hero-area p {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

#hero-area .head-title {
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    color: #FFFFFF;
    max-width: 493px;
    margin-bottom: 20px;
}

#hero-area .buttons button {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    border-radius: 7px;
    padding-top: 14px;
    padding-bottom: 15px;
    outline: none;
    border: none;
    margin-top: 59px;
}

#hero-area .buttons button:first-child {
    background: #084E6E;
    padding-left: 40px;
    padding-right: 31px;
    margin-right: 30px;
}

#hero-area .buttons button:last-child {
    background: transparent;
    padding-left: 40px;
    padding-right: 31px;
    margin-right: 30px;
    border: 1px solid #0D0D19;
    padding-left: 43px;
    padding-right: 37px;
}

@media (max-width:425px) {
    #hero-area .buttons button:first-child {
        background: #084E6E;
        padding-left: 21px;
        padding-right: 21px;
        margin-right: 20px;
    } 
    #hero-area .buttons button:last-child {
        margin-top: 20px;
    }

    #hero-area .head-title{
        font-size: 42px;
        line-height: 48px;
    }
}