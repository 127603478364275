#table .contents {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 12px;
    margin: 0 60px;
    padding: 58px 57px 144px 32px;
    max-width: 1075px;
}

#table .contents .table-first-title {
    margin-bottom: 35px;
}

#table .contents h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #000000;
}

#table .contents .second-title {
    margin-bottom: 25px;
    margin-top: 98px;
}


#table .contents p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0;
}



#table .contents .second-paragraph {
    margin-right: 27px !important;

}

#table .contents .table-top-paragraph {
    margin-top: 67px;
    margin-bottom: 42px;
}

#table .contents .table-containers {
    border-radius: 12px;
    overflow: hidden;
    max-width: 572px;
    border: 1px solid black;
}

.th-border-right {
    border-right: 1px solid black !important;
}

.tr-border-bottom {
    border-bottom: 1px solid black !important;
}






table {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    max-width: 572px;
}

/* th{
    display: flex;
    justify-content: center;
    align-items: center;
} */



th,td {
    padding-top: 13px !important;
    padding-bottom: 16px !important;
}

.th-padding-left{
    padding-left: 26px !important;
}


@media (max-width:1024px) {
    #table .contents {
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 12px;
        margin: 0 0px;
        padding: 58px 32px 100px 32px;

    }
}

@media (max-width:768px) {
    #table .contents {
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 12px;
        margin: 0 0px;
        padding: 58px 20px 100px 20px;

    }

    #table .contents h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }

    #table .contents .second-paragraph {
        margin-right: 0px !important;

    }
}